@font-face {
  font-family: 'Nunito-Bold';
  src: local('Nunito-Bold'), url('./fonts/Nunito-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Nunito-Italic';
  src: local('Nunito-Italic'), url('./fonts/Nunito-Italic.ttf') format('truetype');
  font-weight: italic;
}

.card-body.card-body-eaa {
  min-height: unset;
  padding: 0.5rem;
}

.pointer {
  cursor: pointer;
}

@font-face {
  font-family: 'Nunito-Regular';
  src: local('Nunito-Regular'), url('./fonts/Nunito-Regular.ttf') format('truetype');
  font-weight: normal;
}

/* GENERAL STYLES */
body {
  background: #312f30;
  font-family: 'Nunito-Regular', sans-serif;
}

strong,
.font-weight-bold {
  font-family: 'Nunito-Bold', sans-serif;
}

.table-modal .modal-dialog {
  max-width: none;
}

.table-modal {
  padding: 0 20px;
}

.modal-body {
  background: #fff;
}

.modal-footer {
  border-top: 0;
}

#wrapper {
  background: #e9edf0;
}

#content {
  min-height: 400px;
  padding-bottom: 5rem;
  animation: fadein 0.6s;
  position: relative;
}

body .text-primary {
  color: #d45246 !important;
}

body a,
.btn-link {
  color: #d45246;
}

a:hover,
a:focus,
.btn-link:hover,
.btn-link:focus {
  color: #a6251b;
}

.btn-link,
.btn-link:hover,
.btn-link:focus {
  background: none;
  border-color: transparent;
}

.bg-primary {
  background: #d45246 !important;
}

.bg-primary a {
  color: #fff !important;
}

nav img {
  max-width: 100px;
}

.home h1,
.home h2 {
  font-size: 1.4rem;
  font-weight: normal;
  text-transform: uppercase;
  color: #666;
}

h6 {
  font-weight: normal;
  text-transform: uppercase;
}

.page-title {
  font-weight: normal;
  margin-bottom: 1rem;
  margin-top: 2.2rem;
}

.page-title h1 {
  font-weight: normal;
  margin-bottom: 0;
  font-size: 1.5rem;
}

.page-title p {
  color: #666;
}

img {
  max-width: 100%;
}

/* BUTTONS*/
button,
button:hover,
button:focus {
  outline: 0 !important;
}

.btn {
  border-radius: 3px;
}

.btn-primary {
  color: #fff;
  background-color: #d45246;
  border-color: #d45246;
}

.btn-primary:hover,
.btn-primary:focus {
  border-color: #a6251b;
  background: #a6251b !important;
}

.btn-outline-primary {
  color: #d45246;
  border-color: #d45246;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
  border-color: #d45246;
  color: #fff !important;
  background: #d45246 !important;
}

a.btn-outline-primary:hover,
a.btn-outline-primary:focus {
  color: #fff;
}

a svg,
.btn svg,
footer svg {
  margin-right: 7px;
  margin-bottom: 4px;
}

.btn-primary.disabled,
.btn-primary:disabled:hover,
.btn-primary.disabled:hover,
.btn-primary:disabled:focus,
.btn-primary.disabled:focus,
.btn-primary:disabled {
  cursor: default;
  color: #fff;
  background: #d45246 !important;
  border-color: #d45246 !important;
  opacity: 0.5;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled,
.btn-outline-primary.disabled:hover,
.btn-outline-primary:disabled:hover,
.btn-outline-primary.disabled:focus,
.btn-outline-primary:disabled:focus {
  cursor: default;
  background-color: transparent;
  color: #d45246 !important;
  background: none !important;
  opacity: 0.5;
}

.border-bottom {
  border-bottom: 1px solid rgba(212, 82, 70, 0.3) !important;
}

/* LINKS */
a.link-select {
  color: #333;
}

a.link-select:hover,
a.link-select:focus {
  text-decoration: none;
  color: #333;
}

a.link-select.active div {
  background-color: #f5f5f5;
}

a.link-select .badge {
  color: white;
  background: #d45246;
  padding: 5px 4px 5px 6px;
  border-radius: 50%;
  font-size: 1rem;
  border: 1px solid #e9edf0;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 99;
  text-align: center;
  display: inline-block;
}

a.nav-link.active {
  color: #333;
  font-weight: bold;
}

.navbar-light .navbar-nav .nav-link {
  color: #d45246;
}

/* FORM */
#content form,
.form {
  border: 1px solid #ccc;
  padding: 20px;
}

.react-datetime-picker__wrapper {
  padding: 4px 0.75rem 5px;
  background: white;
  border: 1px solid #ced4da;
  min-width: 262px;
}

#content form.form-inline {
  border: 0;
  padding: 0;
}

.display-info {
  background: white;
  border-radius: 3px;
  padding: 20px 20px 40px;
  border: 1px solid #ccc;
  margin-bottom: 4rem;
}

input,
textarea,
select {
  border-radius: 0 !important;
}

.form-inline select {
  min-width: 150px;
  padding-left: 5px;
  padding-right: 5px;
}

select:disabled {
  -moz-appearance: none;
  -webkit-appearance: none;
}

select:disabled::-ms-expand {
  display: none;
}

label {
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
  color: #666;
  display: block;
}

input::placeholder,
i,
.font-weight-italic {
  font-family: 'Nunito-Italic', sans-serif;
  font-style: italic;
}

.datetimepicker {
  padding: 0;
}

.react-datetime-picker__clock,
.react-datetime-picker__inputGroup__leadingZero {
  display: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='checkbox'] {
  cursor: pointer;
}

input[type='checkbox']:disabled {
  cursor: default;
}

body .rdw-editor-toolbar {
  padding: 0;
  border: 0;
}

body .rdw-editor-wrapper {
  box-sizing: border-box !important;
  height: auto;
}

.skip-to-content {
  clip: rect(0 0 0 0);
  position: absolute;
}

.form-control:disabled,
.form-control[readonly] {
  border-color: transparent;
  padding-left: 0;
}

.form-actions {
  display: flex;
  margin-top: 2rem;
}

.form-actions a {
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  display: inline-block;
}

legend {
  font-size: 0.9rem;
  text-transform: uppercase;
  border-bottom: 1px solid #ccc;
  color: #666;
  margin-top: 1.5rem;
}

.list-header {
  text-transform: uppercase;
  font-size: 0.9rem;
  color: #999;
  border-bottom: 1px solid #ccc;
}

.contact-text div {
  font-size: 0.9rem;
  color: #777;
}

/* CARDS */
.card {
  border-color: #d45246;
  border-radius: 3px;
  /*min-width: 150px;*/
}

.card-body {
  font-size: 0.9rem;
  min-height: 132px;
}

.card-body > legend:first-child {
  margin-top: 0;
}

.card-body p {
  color: #666;
}

.text-intro .card-header {
  background: #d45246;
  color: white;
  border-radius: 0 !important;
  padding: 0;
}

.text-intro .card-header .disabled-link {
  background: #d45246;
  color: white;
  cursor: default;
  pointer-events: none;
}

.text-intro .card-header a {
  display: flex;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 0.75rem 1.25rem;
  background: #d45246;
  color: white;
  min-height: 75px;
}

.text-intro .card-header a:hover,
.text-intro .card-header a:focus {
  text-decoration: none;
  background: #a6251b;
}

.card.card-list .habitation-thumbnail {
  height: 90px;
}

.card.card-list .d-flex .habitation-thumbnail {
  width: 155px;
}

.card.card-list .card-body {
  min-height: 0;
  padding-left: 0;
}

.card.card-list {
  border: 0;
  border-bottom: 1px solid #dee2e6;
}

ol.breadcrumb {
  background: none;
  padding: 0;
  margin: 10px 0 10px 0;
}

iframe {
  width: 100% !important;
}

/* NAVBAR */
.navbar-light .navbar-brand {
  color: #000 !important;
  margin-right: 0;
}

.navbar .dropdown-toggle::after {
  display: none;
}

.navbar .dropdown-menu {
  font-size: 0.9rem;
}

.navbar .notification {
  position: relative;
}

.navbar .notification svg {
  font-size: 1.4rem;
}

.navbar .notification .badge {
  position: absolute;
  right: 8px;
  top: 2px;
}

/* TABS */
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background: none;
}

.nav-tabs .nav-link {
  padding-left: 25px;
  padding-right: 25px;
  margin-right: 5px;
}

.nav-tabs .nav-item .nav-link {
  border: 0;
  border-bottom: 2px solid transparent;
  background: none;
  color: #d45246;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-bottom: 2px solid #a6251b;
  color: #a6251b;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-link.active:focus {
  border-bottom: 2px solid #333;
  color: #333;
  font-weight: bold;
}

/* FOOTER */
footer .bg-primary {
  background: #a6251b !important;
}

.bg-black {
  background: #312f30;
}

footer .bg-black a {
  color: #fff;
  font-size: 0.75rem;
}

footer .bg-black.border-bottom {
  border-bottom: 1px solid #666 !important;
}

footer .bg-black p {
  color: #aaa;
  font-size: 0.85rem;
}

/* TABLE */
.img-square-wrapper {
  max-width: 200px;
}

a.card-title {
  font-size: 1.1rem;
  line-height: 1.4rem;
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

}

.table th,
.table td {
  border-top: 0;
  border-bottom: 1px solid #dee2e6;
  vertical-align: middle;
}

.table th label {
  margin: 0;
}

.table th {
  font-size: 0.8rem;
  font-weight: normal;
  text-transform: uppercase;
}

.table.permissions {
  overflow: auto;
  height: 100px;
}

.table.permissions th {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #ccc;
}

/* RESULTS */
.results {
  word-break: break-word;
  font-size: 1rem;
  list-style-type: none;
  padding: 0;
}

.results li {
  border-top: 0;
  border-bottom: 1px solid #dee2e6;
}

.search-results,
.no-results {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  top: -13px;
  width: 100%;
  z-index: 99;
  padding: 10px 0;
}

.results a {
  font-size: 1.2rem;
}

.no-results {
  font-family: 'Nunito-Italic', sans-serif;
  font-style: italic;
  display: block;
  padding: 5px 10px;
}

.result-legend {
  font-weight: normal;
  text-transform: uppercase;
  font-size: 0.7rem;
}

.search-item {
  cursor: pointer;
  display: block;
  padding: 5px 10px;
}

.search-item:hover,
.search-item:focus {
  background: #eee;
}

/* EMPTY STATE */
.empty-state {
  padding: 3rem 2rem 1.5rem;
  text-align: center;
  border: 1px solid #ccc;
}

.empty-state > svg {
  font-size: 2rem;
  color: #999;
  display: block;
  margin: 0 auto 1.8rem;
}

.empty-state p {
  margin-bottom: 2rem;
  color: #666;
  font-family: 'Nunito-Italic', sans-serif;
  font-style: italic;
}

/* HOME */
.intro-img {
  padding: 80px 50px;
}

.bg-intro {
  position: relative;
  background-image: url('../img/bg-home.jpg');
  background-size: cover;
  background-position: center 70%;
  min-height: 450px;
  max-width: 100%;
  background-color: #333;
}

.bg-intro p {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 180px 15px 0;
  color: white;
  font-size: 3vw;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3), 0 0 2px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-device-width: 500px) {
  .bg-intro p {
    font-size: 3rem;
  }
}

/* FAQS */
.accordion-panel .nav-link {
  padding: 0.5rem 0;
}

.accordion-panel .card-header {
  padding: 0;
  border: 0;
}

.accordion-panel .card-header > button {
  display: block;
  width: 100%;
  text-align: left;
  border: 0;
  padding: 7px 12px;
}

.btn-accordion {
  padding: 0;
  border: 0;
  color: #d45246;
  background: none;
}
.btn-accordion:hover,
.btn-accordion:focus {
  color: #a6251b;
  background: none;
}

.faqs .card-header div {
  position: absolute;
  right: 0;
}

.faqs .card-header .btn {
  border-radius: 0;
}

/* BACK */
.back-button {
  font-size: 2.2rem;
  color: #d45246;
  cursor: pointer;
  border: 0;
  padding: 0;
  background: none;
  line-height: 1;
}

.back-button:hover,
.back-button:focus {
  color: #a6251b;
}

/* LOADING */
.loading {
  padding: 230px 0;
  text-align: center;
  font-size: 4rem;
  color: #666;
}

.loader {
  border: 16px solid #ccc;
  border-top: 16px solid #d45246;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadein {
  from {
    top: -20px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

/* HABITATION */
.habitation-thumbnail {
  background: #e0e0e0;
  height: 160px;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: #666;
}

.habitation-thumbnail svg {
  font-size: 2rem;
}

.habitation-img {
  background: #e0e0e0;
  height: 400px;
  background-size: cover;
  background-position: center;
}

/* CAROUSEL */
body .alice-carousel__dots {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #d45246;
}

body .alice-carousel__prev-btn,
body .alice-carousel__next-btn {
  color: #666;
  font-size: 2rem;
  position: absolute;
  top: 41%;
}

body .alice-carousel__prev-btn svg,
body .alice-carousel__next-btn svg {
  background: #f2f2f2;
  border-radius: 3px;
  cursor: pointer;
}

body .alice-carousel__prev-btn svg:hover,
body .alice-carousel__next-btn svg:hover {
  background: #e0e0e0;
}

body .alice-carousel__dots-item:not(.__custom),
body .alice-carousel__dots-item:not(.__custom):hover,
body .alice-carousel__dots-item:not(.__custom).__active {
  background-color: #f2f2f2;
}

body .alice-carousel__dots-item:not(.__custom):hover,
body .alice-carousel__dots-item:not(.__custom).__active {
  background-color: #666;
}

body .alice-carousel__prev-btn {
  left: 0;
  text-align: left;
}

body .alice-carousel__next-btn {
  right: 0;
  text-align: right;
}

/* SIDE-MENU */
.side-menu .btn {
  text-align: left;
  display: block;
  width: 100%;
}

/* IMAGE UPLOAD */
body .fileContainer {
  box-shadow: none;
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

body .fileContainer p {
  font-size: 0.8rem;
}

body .fileContainer .chooseFileButton {
  color: #d45246;
  border: 1px solid #d45246;
  background: none;
  border-radius: 3px;
}

body .fileContainer .chooseFileButton:hover,
body .fileContainer .chooseFileButton:focus {
  border-color: #d45246;
  background: #d45246 !important;
  color: white;
}

/* MOBILE */
@media only screen and (min-width: 769px) {
  .form-inline .form-group {
    max-width: 190px;
    flex-flow: nowrap;
  }

  .form-inline .form-group {
    margin-bottom: 15px;
  }

  .form-inline .form-group.big-input {
    max-width: 250px;
  }
}

@media only screen and (min-width: 768px) {
  .back-button {
    position: absolute;
    left: -25px;
    top: -8px;
  }
}

@media only screen and (max-width: 768px) {
  .navbar-collapse {
    text-align: center;
    display: block;
  }
  .navbar-collapse li {
    border: 0 !important;
    text-align: center;
  }
  .navbar-collapse li button {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .card.card-list .d-flex .habitation-thumbnail {
    width: 100% !important;
    display: block !important;
  }

  .card-list .d-flex {
    display: block !important;
  }

  .card-list .col-sm-8,
  .card-list .col-sm-1 {
    display: block !important;
    text-align: center;
  }

  .page-title h1 {
    display: inline-block;
  }

  .results .d-flex {
    display: block !important;
  }
  .side-menu {
    margin: 3rem 0;
  }
  footer .bg-black a,
  footer .bg-black p {
    display: block;
    margin: 1rem 0;
    text-align: center;
  }
  table tr,
  table td {
    display: block;
    border: 0 !important;
    padding: 0.5rem !important;
  }

  table tr {
    border-bottom: 2px solid #ccc !important;
  }

  table th {
    display: none;
  }

  .page-title .back-button + h4 {
    display: inline-block;
  }
}

.col-display-contents {
  display: contents;
}

.col-display-contents > input[type='checkbox'] {
  margin-top: 10px;
}

/* PAGINATION COMPONENT */

ul.pagination {
  align-items: center;
  justify-content: center;
}

.page-item.active .page-link {
  background-color: #d45246;
  border-color: #d45246;
}

.page-link,
.page-link:hover,
.page-link:focus {
  color: #d45246;
}

/* CUSTOM ORDER COMPONENT */
.order-symbol,
.order-symbol:hover,
.order-symbol:focus {
  color: white !important;
  background: #d45246 !important;
  border-radius: 5px;
  border: 3px solid #d45246;
  z-index: 99;
  text-align: center;
  display: flex;
  font-size: 1.1rem;
  left: 34px;
  position: absolute;
  top: 34px;
  width: 26px;
  height: 26px;
  font-weight: bold;
  justify-content: space-around;
  align-items: center;
}

.link-select .order-symbol svg {
  margin: 0;
}

.order-symbol.unselected {
  background: white !important;
}

.order-symbol.reorder {
  left: 8px;
  top: 10px;
}

.reorder {
  font-size: 1.2rem;
  color: #666;
}

.space-between {
  justify-content: space-between;
}

table .permission-description {
  color: #666;
  font-size: 0.8rem;
}

table .form-group {
  margin: 0;
}

.print-only {
  display: none;
}

.col-50 {
  width: 50% !important;
}

.maps-google {
  display: none;
}

.card-body span > p {
  color: #212529 !important;
}

/* Print */
@media only print {
  .col-md-9.display-info {
    width: 100% !important;
    max-width: 100% !important;
    flex: none !important;
  }

  .header-habitation {
    max-width: 250px;
  }

  body a.maps-google {
    display: flex !important;
  }

  body a.competition-to-print {
    display: flex !important;
  }

  .print-only {
    display: flex;
  }

  .display-info {
    border: 0;
    padding: 0;
    margin-bottom: 0;
    flex-direction: column-reverse !important;
    display: flex !important;
  }

  .habitation-img,
  .habitation-thumbnail {
    display: block !important;
    color-adjust: exact !important;
    clear: both;
    position: relative !important;
    overflow-x: visible;
    -webkit-region-break-inside: avoid;
    page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-inside: avoid; /* or 'auto' */
  }

  .habitation-img {
    width: 100% !important;
  }

  .alice-carousel__stage-item {
    display: block;
    margin-bottom: 15px;
  }

  .collapse:not(.show) {
    display: block;

    -webkit-region-break-inside: avoid;
    page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-inside: avoid; /* or 'auto' */
  }

  .card.card-list {
    -webkit-region-break-inside: avoid;
    page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-inside: avoid; /* or 'auto' */
  }

  .accordion > .card {
    -webkit-region-break-inside: avoid;
    page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-inside: avoid; /* or 'auto' */
  }

  .accordion-panel .card-header > button {
    font-weight: bold;
    color: #000 !important;
    display: block !important;
    width: 100%;
    text-align: left;
    border: 0;
    padding: 7px 12px;
  }

  .page-title h1 {
    text-align: center;
  }

  .col-md-8.offset-md-2,
  .col-lg-8.offset-lg-2 {
    width: 100%;
    max-width: 100%;
    margin: 0;
    flex: auto;
  }

  .card {
    border-color: #333;
  }

  .side-menu,
  .back-button,
  footer,
  .alice-carousel__prev-btn,
  .alice-carousel__next-btn,
  .btn,
  .alice-carousel__dots,
  .no-print,
  a,
  .alert {
    display: none !important;
  }

  footer {
    display: none !important;
  }

  #content {
    padding-bottom: 0rem;
    background: white;
  }

  body {
    background: transparent !important;
  }
}

.documentSubmission .fileUploader .fileContainer img {
  display: none;
}

#content form.upload-custom {
  padding: 0;
  border: 0;
}

.upload-custom .form-group input[type='file'] {
  display: none;
}

.upload-custom .form-group input[type='text'] {
  background: white;
}

.required {
  color: #f44336;
  margin-left: 3px;
}

.upload-custom .form-control {
  border-color: #d45246;
  background: none;
  color: #777;
  white-space: nowrap;
  overflow-x: clip;
  text-overflow: ellipsis;
}

.upload-custom div button {
  border-radius: 0 3px 3px 0;
}

.upload-custom div button svg {
  margin: 0;
}

.form-inline .upload-custom .form-group {
  max-width: 100% !important;
}

a.file-download {
  color: #d45246;
}

.radio-form label {
  margin-left: 5px;
  min-width: fit-content;
}

.radio-form .form-check input {
  margin-left: 8px;
}

.radio-form .form-check,
.radio-form .form-label {
  cursor: pointer;
}

#content .radio-form {
  display: flex;
  flex-flow: row wrap;
  padding: 0;
  border: 0;
  align-content: flex-end;
  flex-direction: column;
  padding: 0;
  border: 0;
}

.radio-form .form-group {
  display: flex;
  flex: 0 0 auto;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 0;
}

.regular-radio .form-check {
  display: inline-block;
}

.regular-radio .form-check-input {
  margin-top: 0.6rem;
  margin-left: -1rem;
}

.regular-radio .form-check-label {
  margin-right: 15px;
  min-height: 33px;
}

.font-small {
  font-size: 0.8rem;
  text-transform: uppercase;
  color: #777;
}

.border-primary {
  border-color: #d45246 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.inputSuffix + span {
  background: none;
  color: #999;
}

.inputSuffix {
  border-right: 0;
}
